<template>
  <div class="video-player">

    <!-- Poster -->
    <img loading="lazy"
      defer 
      :src="poster.url" 
      :alt="title"
      class="img-fluid"
    >
    
    <!-- Player -->
    <iframe
      ref="player" 
      allow="autoplay"
      :title="title" 
      src="" 
      :data-src="`https://player.vimeo.com/video/${video}?autoplay=1&loop=1&controls=0`"
    >
    </iframe>
    
    <!-- Button -->
    <button aria-label="Play Reel" 
      @click="toogleVideo" 
      @mouseenter="soundStore.playHover" 
      :class="videoPlaying ? 'stop' : 'start'"
    ></button>

  </div>
</template>

<script setup>
  const props = defineProps(['video','poster','title'])
  const player = ref()
  const videoPlaying = ref(false)

  const gtm = useGtm()

  const soundStore = useSoundStore()

  const toogleVideo = () => {

    gtm.trackEvent({
      event: 'interaction',
      category: 'Showreel',
      action: 'click',
      label: 'Video Played'
    })

    if(videoPlaying.value == true) {
      soundStore.playMusic()
      player.value.src = ""
    } else {
      soundStore.pauseMusic()
      player.value.src = player.value.dataset.src;
    }
    videoPlaying.value = !videoPlaying.value
  }
  
</script>

<style lang="scss">
  .video-player {
    aspect-ratio: 16 / 9;
    display: grid;
    inline-size: 100%;
    position: relative;
  
    :is(iframe, img) { 
      block-size: 100%;
      border: 0;
      border-radius: inherit;
      inline-size: 100%;
      inset: 0;
      object-fit: cover;
      position: absolute;
    }
  
    button {
      background-color: var(--color-black);
      block-size: var(--button-h, 50px);
      border: 0;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      display: grid;
      transition: all .2s ease-out;
      cursor: pointer;
      position: absolute;

      &:hover {
        background: var(--color-primary);
      }

      &.start {
        place-self: center;

        &::before {
          aspect-ratio: 1;
          background: #FFF;
          block-size: 1.5em;
          clip-path: polygon(20% 0%, 20% 100%, 100% 50%);
          content: '';
          place-self: center;
        }
      }

      &.stop {
        place-self: center;
        bottom: 50px;

        &::before {
          aspect-ratio: 1;
          background: #FFF;
          block-size: 1.5em;
          clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%);
          content: '';
          place-self: center;
        }
      }
    }
  }
</style>